// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import LocalTime from "local-time"
LocalTime.start()

import "ahoy.js"
import "trix";
import "chartkick/chart.js"

// local js files
import "./stripe"

document.addEventListener("turbo:load", () => {    
	// Begin Mobile Small devices menubar
	var toggle = document.getElementById("nav-toggler");
	var topNav = document.getElementById("navigation");

	// Listen for click event on toggle var
	if (toggle) {
		toggle.addEventListener(
			"click",
			function () {
				// console.log("toggle clicked");
				// toggle class "hidden" on topNav var
				topNav.classList.toggle("hidden");
			},
			false
		);
	}
	// End Mobile Small devices menubar
})

// Function to handle checkbox change
// Used in app/views/providers/outcomes/_form_toggle_outcomes.html.erb
function handleOutcomesCheckboxChange(event) {
	const checkbox = event.target;
	const originalChecked = checkbox.checked;

	if (!confirm("Are you sure you want to update the Client Outcomes Preference?")) {
		checkbox.checked = !originalChecked;
	} else {
		checkbox.form.requestSubmit();
	}
}

// Make the function available globally
window.handleOutcomesCheckboxChange = handleOutcomesCheckboxChange;
