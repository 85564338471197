import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle"
export default class extends Controller {
  static targets = ["stopCollection", "collectLongtermOutcomes"]

  connect() {
    console.log("Connected to toggle controller");
    this.toggleCollectLongtermOutcomes();
  }

  toggleCollectLongtermOutcomes() {
    const isStopCollectionChecked = this.stopCollectionTarget.checked;
    this.collectLongtermOutcomesTarget.disabled = !isStopCollectionChecked;
    if (!isStopCollectionChecked) {
      this.collectLongtermOutcomesTarget.checked = false;
    }
  }
}
